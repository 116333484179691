export function Logo() {
  return (
    <svg width="161" height="226" viewBox="0 0 161 226" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113 0C50.5918 0 0 50.5918 0 113C0 175.408 50.5918 226 113 226C175.408 226 226 175.408 226 113C226 83.0305 214.095 54.2885 192.903 33.0969C171.711 11.9053 142.969 0 113 0ZM113 195.867C67.234 195.867 30.1333 158.766 30.1333 113C30.1333 67.234 67.234 30.1333 113 30.1333C158.766 30.1333 195.867 67.234 195.867 113C195.867 134.978 187.136 156.055 171.596 171.596C156.055 187.136 134.978 195.867 113 195.867ZM164.227 85.1267C164.227 98.0244 153.771 108.48 140.873 108.48C127.976 108.48 117.52 98.0244 117.52 85.1267C117.52 72.229 127.976 61.7733 140.873 61.7733C153.737 61.8555 164.144 72.2632 164.227 85.1267ZM164.227 140.873C164.227 153.771 153.771 164.227 140.873 164.227C127.976 164.227 117.52 153.771 117.52 140.873C117.52 127.976 127.976 117.52 140.873 117.52C153.737 117.602 164.144 128.01 164.227 140.873ZM108.48 140.873C108.48 153.771 98.0244 164.227 85.1267 164.227C72.229 164.227 61.7733 153.771 61.7733 140.873C61.7733 127.976 72.229 117.52 85.1267 117.52C97.9902 117.602 108.398 128.01 108.48 140.873ZM108.48 85.1267C108.48 98.0244 98.0244 108.48 85.1267 108.48C72.229 108.48 61.7733 98.0244 61.7733 85.1267C61.7733 72.229 72.229 61.7733 85.1267 61.7733C97.9902 61.8555 108.398 72.2632 108.48 85.1267Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
